// src/components/ErrorFallback.tsx

import React from "react";

interface ErrorFallbackProps {
  error: Error;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error }) => (
  <div role="alert" style={{ padding: "2rem", textAlign: "center" }}>
    <h2>Something went wrong!</h2>
    <p>{error.message}</p>
    {/* Optional: Add a button to navigate or retry */}
  </div>
);

export default ErrorFallback;
